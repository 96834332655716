import React from 'react';
import PropTypes from 'prop-types';
import { MdClose as CloseIcon } from 'react-icons/md';
import cx from 'classnames';

import polyglot from 'services/localization';
import BodyEnd from 'components/common/bodyEnd';
import ButtonModern from 'components/common/buttonModern';
import modalStyles from 'styles/modal.pcss';
import styles from './responsiveModal.styles.pcss';
import InteractableDiv from '../interactableDiv';

const getStyles = (width, height, style) => {
  const s = { ...style } || {};
  if (width > 0) {
    s.width = width;
  }
  if (height > 0) {
    s.height = height;
  }
  return s;
};

const getProgressBarWidth = (progression) => {
  if (progression > 100) { return 100; }
  if (progression < 0) { return 0; }
  return progression;
};

const ModalHeader = ({
  title,
  headerClassName,
  classAnimation,
  onClose,
  hideClose,
  className,
  headerStyle,
  dataTest,
}) => {
  return (
    <div style={headerStyle} className={cx(
      modalStyles.header,
      styles.responsiveModalHeader,
      classAnimation,
      className,
    )}>
      {!!title && (
      <h3 className={cx(headerClassName, modalStyles.headerTitle)}>
        {title}
      </h3>
      )}
      {!hideClose && (
      <CloseIcon
        className={styles.responsiveModalClose}
        data-test={dataTest}
        size={20}
        onClick={onClose}
      />
      )}
    </div>
  );
};

ModalHeader.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  hideClose: PropTypes.bool,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  headerStyle: PropTypes.shape({}),
  classAnimation: PropTypes.string,
  dataTest: PropTypes.string,
};

ModalHeader.defaultProps = {
  title: '',
  onClose: () => null,
  hideClose: false,
  className: '',
  headerClassName: '',
  headerStyle: null,
  classAnimation: '',
  dataTest: '',
};

const ResponsiveModal = ({
  isOpen, onClose, title, children,
  shouldCloseOnOverlayClick, hideClose, width, height,
  className, headerClassName, dataTest, progression, showProgressBar,
  classAnimation, style, headerStyle, hidePadding,
}) => (
  <>
    {isOpen && (
      <BodyEnd>
        <InteractableDiv
          tabIndex={-1}
          className={styles.responsiveModalWrapper}
          onMouseDown={(e) => e.target === e.currentTarget
            && shouldCloseOnOverlayClick
            && onClose()
          }
        >
          <div
            style={getStyles(width, height, style)}
            data-test={dataTest}
            className={cx(
              styles.responsiveModalContainer,
              className,
              { [styles.responsiveModalContainerHidePadding]: hidePadding },
            )}
            id={`responsiveModalContainer-${Math.random().toString(36).substr(2, 9)}`}
          >
            {showProgressBar && (
              <div className={styles.containerProgressBar}>
                <div className={styles.responsiveModalProgressBar} />
                <div style={{ width: `${getProgressBarWidth(progression)}%` }} className={styles.responsiveModalProgressBarComplition} />
              </div>
            )}
            <ModalHeader
              title={title}
              headerClassName={headerClassName}
              headerStyle={headerStyle}
              classAnimation={classAnimation}
              onClose={onClose}
              hideClose={hideClose}
              dataTest={`${dataTest}CloseModal`}
            />
            <div
              className={cx(
                { [styles.responsiveModalChildren]: !hidePadding },
                classAnimation,
              )}
            >
              {children}
            </div>
          </div>
        </InteractableDiv>
      </BodyEnd>
    )}
  </>
);

ResponsiveModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  shouldCloseOnOverlayClick: PropTypes.bool,
  hideClose: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  headerStyle: PropTypes.shape({}),
  dataTest: PropTypes.string,
  showProgressBar: PropTypes.bool,
  progression: PropTypes.number,
  classAnimation: PropTypes.string,
  style: PropTypes.shape({}),
  hidePadding: PropTypes.bool,
};

ResponsiveModal.defaultProps = {
  shouldCloseOnOverlayClick: true,
  hideClose: false,
  width: 0,
  height: 0,
  className: '',
  headerClassName: '',
  headerStyle: null,
  dataTest: '',
  showProgressBar: false,
  progression: 0,
  classAnimation: '',
  style: null,
  hidePadding: false,
};

export default ResponsiveModal;

export function ActionButtons({
  onSubmit = () => null,
  onClose = () => null,
  submitLabel = polyglot.t('actions.save'),
  cancelLabel = polyglot.t('actions.cancel'),
  submitButtonStyle = 'add',
  isSubmitButtonLoading = false,
  isSubmitButtonDisabled = false,
  isCancelButtonLoading = false,
  isCancelButtonDisabled = false,
  hidePrimaryButton = false,
  hideSecondaryButton = false,
  dataTestSubmit = '',
  dataTestSecondary = '',
  withMargin = false,
  hasSubmitButtonIcon = false,
  wrapperRef = {},
}) {
  return (
    <div
      ref={wrapperRef}
      className={cx(modalStyles.footerModern, withMargin && modalStyles.footerModernMargin)}
    >
      {!hidePrimaryButton && (
      <ButtonModern
        dataTest={dataTestSubmit}
        isLoading={isSubmitButtonLoading}
        disabled={isSubmitButtonDisabled}
        styleType={submitButtonStyle}
        type="submit"
        onClick={onSubmit}
        withIcon={hasSubmitButtonIcon}
      >
        {submitLabel}
      </ButtonModern>
      )}
      {!hideSecondaryButton && (
        <ButtonModern
          isLoading={isCancelButtonLoading}
          disabled={isCancelButtonDisabled}
          onClick={onClose}
          styleType="cancel"
          dataTest={dataTestSecondary}
        >
          {cancelLabel}
        </ButtonModern>
      )}
    </div>
  );
}

ActionButtons.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  submitLabel: PropTypes.oneOfType(PropTypes.string, PropTypes.node),
  cancelLabel: PropTypes.string,
  submitButtonStyle: PropTypes.string,
  isSubmitButtonLoading: PropTypes.bool,
  isSubmitButtonDisabled: PropTypes.bool,
  isCancelButtonLoading: PropTypes.bool,
  isCancelButtonDisabled: PropTypes.bool,
  hidePrimaryButton: PropTypes.bool,
  hideSecondaryButton: PropTypes.bool,
  dataTestSubmit: PropTypes.string,
  dataTestSecondary: PropTypes.string,
  withMargin: PropTypes.bool,
  hasSubmitButtonIcon: PropTypes.bool,
  wrapperRef: PropTypes.shape({}),
};
