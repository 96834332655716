import polyglot from 'services/localization';
import { getHasAddon, hasBoughtApplication } from 'shared-library/src/services/subscriptionService';
import { ACTIVE_STATUSES } from 'shared-library/src/definitions/subscription';
import {
  faTools,
  faUserCog,
  faPrint,
  faBook,
  faMoneyCheckAlt,
  faFileImport,
  faCashRegister,
} from 'fontawesome/pro-solid-svg-icons';
import { getFirstPartOfPath } from './regexp';

export function hasAddon(subscriptionData, addon) {
  return !window.config.stripe.enabled || getHasAddon(subscriptionData, addon);
}

export function hasPermission(user, garage, permission) {
  const { roles } = user.garages.find(({ garageId }) => garageId === user.currentGarageId);
  switch (permission) {
    case 'canInviteUser':
    case 'canRemoveUsers':
    case 'changeGarageSettings':
      return roles.includes('admin');
    case 'fetchInvoices':
    case 'fetchCashbook':
    case 'fetchEmails':
    case 'fetchProducts':
    case 'editProducts':
    case 'fetchOffers':
    case 'fetchBin':
    case 'fetchBanking':
      return roles.includes('admin') || roles.includes('office');
    case 'closeInvoices':
      return roles.includes('admin') || roles.includes('office');
    case 'fetchReports':
    case 'fetchDashboard':
    case 'setupCashRegister':
      return roles.includes('admin') || roles.includes('accountant');
    case 'seeInvoicePrice':
      return roles.includes('admin') || roles.includes('accountant') || roles.includes('office');
    case 'fetchClients':
      return roles.includes('admin') || roles.includes('accountant') || roles.includes('office') || roles.includes('worker');
    case 'fetchVehicles':
    case 'fetchOrders':
    case 'accessCalendar':
      return roles.includes('admin') || roles.includes('worker') || roles.includes('office');
    case 'fetchFirstSteps':
      return garage.onboarding !== null;
    case 'fetchSdi':
      return garage.country === 'IT' && !roles.includes('worker');
    default:
      return false;
  }
}
export function hasRole(user, role) {
  const { roles } = user.garages.find(({ garageId }) => garageId === user.currentGarageId);
  return roles.includes(role);
}

export function currentUserHasRole(role) {
  const { user } = window.config;
  const { roles } = user.garages.find(({ garageId }) => garageId === user.currentGarageId);

  return roles.includes(role);
}

export function hasAnyRole(user, roleList) {
  for (const r of roleList) {
    if (hasRole(user, r)) {
      return true;
    }
  }
  return false;
}

const isMenuItemAccessible = (menuItem, roles, garageCountry) => {
  const hasRequiredRole = menuItem.neededRole.length === 0 ||
    menuItem.neededRole.some((role) => roles.includes(role));
  let isGarageCountrySupported = true;
  if (menuItem.supportedCountries) {
    isGarageCountrySupported = menuItem.supportedCountries.includes(garageCountry);
  }

  return hasRequiredRole && isGarageCountrySupported;
};

export function getDropdownMenuItems(roles, history, garage) {
  // To allow a menu item for a specific role, add that role in the neededRole field. If the user
  // has at least one of the roles specified in the neededRole field of a menu item,
  // then he can access that menu item.
  // If the neededRole field is an empty array, then every role can access that menu item.
  const allMenuItems = [{
    neededRole: ['admin'],
    title: polyglot.t('settings.garage.mainHeading'),
    icon: faTools,
    onClick: () => history.push('/garageSettings'),
    enabledOnMobile: true,
  }, {
    neededRole: [],
    title: polyglot.t('settings.user.mainHeading'),
    icon: faUserCog,
    onClick: () => history.push('/userSettings'),
    enabledOnMobile: true,
  }, {
    neededRole: ['admin'],
    title: polyglot.t('settings.print.mainHeading'),
    icon: faPrint,
    onClick: () => history.push('/printSettings'),
    enabledOnMobile: false,
  }, {
    neededRole: ['admin', 'accountant'],
    title: polyglot.t('settings.cash.mainHeading'),
    icon: faBook,
    onClick: () => history.push('/cashSettings'),
    enabledOnMobile: false,
  }, {
    neededRole: ['admin', 'accountant'],
    title: polyglot.t('settings.cashRegister.mainHeading'),
    icon: faCashRegister,
    onClick: () => history.push('/cashRegisterSettings'),
    enabledOnMobile: true,
    supportedCountries: ['AT'],
  }, {
    neededRole: ['admin'],
    title: polyglot.t('settings.interfaces.mainHeading'),
    icon: faFileImport,
    onClick: () => history.push('/interfacesSettings'),
    enabledOnMobile: true,
  }, {
    neededRole: ['admin'],
    title: polyglot.t('header.subscription'),
    icon: faMoneyCheckAlt,
    onClick: () => history.push(`/subscription${hasBoughtApplication(garage?.stripeObject) && ACTIVE_STATUSES.includes(garage?.subscriptionData?.status) ? '/invoices' : ''}`),
    enabledOnMobile: false,
    hiddenOnApplePWA: true,
  },
  ];
  const accessibleMenuItems
    = allMenuItems.filter((menuItem) => isMenuItemAccessible(menuItem, roles, garage.country));
  return accessibleMenuItems.map(({ neededRole, ...rest }) => rest);
}

const allowedPath = {
  worker: ['/clients', '/vehicles', '/userSettings', '/invoices'], // '/offers',
  office: ['/clients', '/vehicles', '/offers', '/userSettings', '/invoices'],
  accountant: ['/reports', '/userSettings', '/invoices', '/clients'],
};

export function getCurrentUserPermissions(user, currentGarageId) {
  return Object.keys(user).length > 0 ? user.garages.find((garage) => garage.garageId === currentGarageId).roles[0] : '';
}
export function getInitialRoute(roles, pathname, initialRoute) {
  if (pathname === '/' && roles.includes('admin')) {
    return initialRoute || '/';
  }

  if (roles.includes('admin')) {
    return pathname;
  }
  const firstPartOfPathname = getFirstPartOfPath(pathname);
  if (roles.includes('worker')) {
    return allowedPath.worker.includes(firstPartOfPathname) ? pathname : '/clients';
  }

  if (roles.includes('office')) {
    return allowedPath.office.includes(firstPartOfPathname) ? pathname : '/clients';
  }

  return allowedPath.accountant.includes(firstPartOfPathname) ? pathname : '/reports';
}

export default [{
  key: 'admin',
  label: polyglot.t('roles.admin'),
}, {
  key: 'worker',
  label: polyglot.t('roles.worker'),
}, {
  key: 'office',
  label: polyglot.t('roles.office'),
}, {
  key: 'accountant',
  label: polyglot.t('roles.accountant'),
}];
