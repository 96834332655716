import * as apiClient from 'resources/api.client';

export function fetchGarage(garageId) {
  return apiClient.get(`garages/${garageId}`, { garageId });
}

export function getGarageStatistics(garageId) {
  return apiClient.get(`garages/statistics/${garageId}`, { garageId });
}

export function getFirstStepsStatistics(garageId) {
  return apiClient.get(`garages/firstSteps/${garageId}`, { garageId });
}

export function updateGarage(garageId, data) {
  return apiClient.put(`garages/${garageId}`, { garageId }, data);
}

export const updateIntagrationsUsed = (garageId, data) => {
  return apiClient.put(`garages/${garageId}/integrations`, { garageId }, data);
};

export function updateTaxRates(garageId, data) {
  return apiClient.post(`garages/${garageId}/invoice-settings/update-tax-rates`, { garageId }, data);
}

export function updateEmailSettings(garageId, data) {
  return apiClient.post(`garages/${garageId}/update-email-settings`, { garageId }, data);
}

export function updateInvoiceSettings(garageId, data) {
  return apiClient.post(`garages/${garageId}/invoice-settings/update-settings`, { garageId }, data);
}

export function updateInvoiceDefaultDueDays(garageId, data) {
  return apiClient.post(`garages/${garageId}/invoice-settings/update-default-due-days`, { garageId }, data);
}

export function updateNumber(garageId, data) {
  return apiClient.post(`garages/${garageId}/invoice-settings/update-number`, { garageId }, data);
}

export function uploadImage(garageId, file) {
  const body = new FormData();
  body.append('file', file);

  return apiClient.postRaw(`garages/${garageId}/invoice-settings/files/upload`, { garageId }, body);
}

export function fetchImage(garageId, data) {
  return apiClient.get(`garages/${garageId}/invoice-settings/files`, { garageId, id: data._id });
}

export function renameImage(garageId, id, data) {
  return apiClient.post(`garages/${garageId}/invoice-settings/files/rename`, { id, garageId }, data);
}

export function removeImages(garageId, data) {
  return apiClient.post(`garages/${garageId}/invoice-settings/files/remove`, { garageId }, data);
}

export const deleteGarageData = (garageId, data) => {
  return apiClient.post(`garages/${garageId}/delete-data`, { garageId }, data);
};
export function getGarageDataCount(garageId) {
  return apiClient.get(`garages/${garageId}/data-count`, { garageId });
}

export const stripeCheckout = (garageId, data) => {
  return apiClient.post(`garages/${garageId}/stripe/changeSubscription`, { garageId }, data);
};
export const getPaymentMethodData = (garageId, paymentMethodId) => {
  return apiClient.get(`garages/${garageId}/stripe/paymentMethodData`, { garageId, paymentMethodId });
};
export const getSetupIntent = (garageId, paymentMethodId) => {
  return apiClient.get(`garages/${garageId}/stripe/getSetupIntent`, { garageId, paymentMethodId });
};
export const setDefaultPaymentMethod = (garageId, data) => {
  return apiClient.post(`garages/${garageId}/stripe/setDefaultPaymentMethod`, { garageId }, data);
};
export const getNextFullInvoicePreview = (garageId, data) => {
  return apiClient.get(`garages/${garageId}/stripe/getNextFullInvoicePreview`, { ...data, garageId });
};
export const getNextInvoiceForUpdates = (garageId, data) => {
  return apiClient.get(`garages/${garageId}/stripe/getNextInvoiceForUpdates`, { ...data, garageId });
};
export const getNextInvoicePreview = (garageId, data) => {
  return apiClient.get(`garages/${garageId}/stripe/getNextInvoicePreview`, { ...data, garageId });
};
export const getOpenInvoices = (garageId) => {
  return apiClient.get(`garages/${garageId}/stripe/getOpenInvoices`, { garageId });
};
export const getPriceList = (garageId) => {
  return apiClient.get(`garages/${garageId}/stripe/getPriceList`, { garageId });
};
export const getInvoicesForCustomer = (garageId, customerId) => {
  return apiClient.get(`garages/${garageId}/stripe/getInvoicesForCustomer`, { customerId, garageId });
};
export const cancelSubscription = (garageId, subscriptionId) => {
  return apiClient.get(`garages/${garageId}/stripe/cancelSubscription`, { subscriptionId, garageId });
};
export const getCustomerWithPaymentData = (garageId, customerId) => {
  return apiClient.get(`garages/${garageId}/stripe/getCustomerWithPaymentData`, { customerId, garageId });
};
export function setupFinanzOnlineAccount(garageId, params) {
  return apiClient.post(`garages/${garageId}/setup-finanz-online-account`, { garageId }, params);
}
export function setupFiskalyTse(garageId) {
  return apiClient.post(`garages/${garageId}/setup-fiskaly-tse`, { garageId }, {});
}
export function exportDsfinvkFile(garageId, startDate, endDate) {
  return apiClient.post(`garages/${garageId}/initiateDsfinvkExport`, { garageId, startDate: startDate.toISOString(), endDate: endDate.toISOString() }, {});
}
export function getDsfinvkExports(garageId) {
  return apiClient.get(`garages/${garageId}/getDsfinvkExports`, { garageId });
}
export function getDsfinvkExportFile(garageId, exportId) {
  return apiClient.get(`garages/${garageId}/getDsfinvkExportFile`, { garageId, exportId }, {}, {}, { returnArrayBuffer: true });
}
export function initiateTseExport(garageId, startDate, endDate) {
  return apiClient.post(`garages/${garageId}/initiateTseTarExport`, { garageId, startDate: startDate.toISOString(), endDate: endDate.toISOString() });
}
export function getTseExports(garageId) {
  return apiClient.get(`garages/${garageId}/getTseExports`, { garageId });
}
export function getTseExportFile(garageId, exportId) {
  return apiClient.get(`garages/${garageId}/getTseExportFile`, { garageId, exportId }, {}, {}, { returnArrayBuffer: true });
}
export function initiateCashpointClosing(garageId, startDate, endDate) {
  return apiClient.post(`garages/${garageId}/initiateCashpointClosing`, { garageId, startDate: startDate.toISOString(), endDate: endDate.toISOString() });
}
export function transferOwnershipToUser(garageId, data) {
  return apiClient.post(`garages/${garageId}/transferOwnership`, { garageId }, data);
}

export function getCredentialsForSupplier(garageId, data) {
  return apiClient.get(`garages/${garageId}/credentialsForSupplier`, { garageId }, data);
}

export function updateCredentialsForSupplier(garageId, data) {
  return apiClient.post(`garages/${garageId}/credentialsForSupplier`, { garageId }, data);
}

export function deleteCredentialsForSupplier(garageId, data) {
  return apiClient.post(`garages/${garageId}/deleteCredentialsForSupplier`, { garageId }, data);
}

export function getLatestReceiptOrCashbookEntryDate(garageId) {
  return apiClient.get(`garages/${garageId}/latestReceiptOrCashbookEntryDate`, { garageId });
}
