import * as apiClient from 'resources/api.client';

export function changeGarage(userId, currentGarageId, garageId) {
  return apiClient.post(
    `users/${userId}/change-garage`,
    { garageId: currentGarageId },
    { garageId },
  );
}

export function fetchUsers(data) {
  return apiClient.get('users', data);
}

export function getUserById(userId, garageId) {
  return apiClient.get(`users/${userId}`, { garageId });
}

export function createSupportChatConversation(data) {
  return apiClient.post('users/supportChatConversations', {}, data);
}

export function submitRating(data, garageId, userId) {
  return apiClient.post(`users/${userId}/rating`, { garageId }, data);
}

export function updateUser(userId, garageId, userData) {
  return apiClient.put(`users/${userId}/`, { garageId }, userData);
}

export function markAllAlarmsAsRead(userId, garageId) {
  return apiClient.post(`users/${userId}/mark-all-alarms-as-read`, { garageId }, {});
}

export function markAlarmAsRead(userId, garageId, uid) {
  return apiClient.post(`users/${userId}/mark-alarm-as-read`, { garageId, eventUid: uid }, {});
}

export function changePassword(userId, garageId, data) {
  return apiClient.post(`users/${userId}/change-password`, { garageId }, data, { credentials: 'include' });
}

export function deactivate2FA(userId, garageId, password) {
  return apiClient.post(`users/${userId}/deactivate2FA`, { garageId }, password);
}

export function activate2FA(userId, garageId) {
  return apiClient.post(`users/${userId}/activate2FA`, { garageId }, {});
}

export function removeUsers(userIds, garageId) {
  return apiClient.post('users/remove', { garageId }, { userIds });
}

export const getHashForShopLogin = (userId, garageId) => apiClient.get(`users/${userId}/hash-for-shop-login`, { garageId });

export function changeGarageRole(userId, garageId, role) {
  return apiClient.post(`users/${userId}/changeGarageRole`, { garageId }, { role });
}

export function updateLastLogin(userId, garageId) {
  return apiClient.post(`users/${userId}/last-login`, { garageId });
}
