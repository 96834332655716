export const addPageSize = (data) => ({
  ...data,
  pageSize: data.pageSize || Math.min(Math.round(window.screen.height / 15), 300),
});

export const updateApp = async () => {
  const currVersion = window.config.version;
  const { version: deployedAppVersion } = await (await fetch(`${window.config.webUrl}/info`)).json();
  const lastRefresh = localStorage.getItem('lastUpdateRefresh') || 0;

  if (
    window.config.serverVersion
    && currVersion !== deployedAppVersion
    && Number(lastRefresh) + 1000 * 30 < Date.now() // Only if last update was < 30 seconds ago
  ) {
    localStorage.setItem('lastUpdateRefresh', Date.now());
    window.location.reload();
  }
};

export const updateAppByAction = (actionTypesToUpdate, actionType) => {
  if (actionTypesToUpdate.includes(actionType)) {
    updateApp();
  }
};

export const getCustomer = ({
  address,
  city,
  _id,
  country,
  email,
  firstName,
  formOfAddress,
  lastName,
  phone,
  title,
  zipCode,
}) => ({
  clientId: _id,
  address,
  city,
  country,
  email,
  firstName,
  formOfAddress,
  lastName,
  phone,
  title,
  zipCode,
});

export const updateCustomerInfo = (list, client) => list
  .map((item) => (item.client.clientId === client.clientId ? { ...item, client } : item));

export const getAccountData = ({ client, supplier }) => {
  if (client) {
    return `${client.lastName} ${client.firstName}`.trim();
  }

  if (supplier) {
    return supplier.title.trim();
  }

  return '---';
};
