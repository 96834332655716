import { addPageSize } from 'resources/utils';
import localStorage from 'services/localStorage';
import * as api from './user.api';
import * as fromUser from './user.selectors';

export const CHANGE_GARAGE = 'changeGarage';
export const CHANGE_PASSWORD = 'changePassword';
export const FETCH_USERS = 'fetchUsers';
export const UPDATE_USER = 'updateUser';
export const REMOVE_USERS = 'removeUsers';
export const CHANGE_USERS_FILTER = 'changeUsersFilter';
export const RESET_USERS_STATE = 'resetUsersState';
export const TOGGLE_CUSTOMER_MODE = 'toogleCustomerMode';
export const SUBMIT_RATING = 'submitRating';

export const changeGarage = (garageId) => (dispatch, getState) => {
  const state = getState();
  const userId = fromUser.getCurrentUserId(state);
  const currentGarageId = fromUser.getCurrentGarageId(state);

  localStorage.setItem('currentGarageId', garageId);
  if (currentGarageId !== garageId) {
    api.changeGarage(userId, currentGarageId, garageId)
      .then(() => {
        localStorage.setItem('currentGarageId', garageId);
        const basePath = window.location.pathname.split('/')[1];
        window.location.pathname = basePath;
      });
  }
};

export const changeGarageRole = (userId, role) => (dispatch, getState) => {
  const currentGarageId = fromUser.getCurrentGarageId(getState());
  return api.changeGarageRole(userId, currentGarageId, role)
    .then((payload) => dispatch({ type: UPDATE_USER, payload }));
};

export const toggleCustomerMode = () => ({
  type: TOGGLE_CUSTOMER_MODE,
});

export const fetchUsers = (options = {}) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);
  const data = addPageSize({ ...options, garageId });

  return api.fetchUsers(data)
    .then((payload) => dispatch({ type: FETCH_USERS, data, payload }));
};

export const createSupportChatConversation = (message) => {
  return api.createSupportChatConversation({ message });
};

export const submitRating = (data) => (dipatch, getState) => {
  const state = getState();
  const userId = fromUser.getCurrentUserId(state);
  const garageId = fromUser.getCurrentGarageId(state);
  return api.submitRating(data, garageId, userId)
    .then((payload) => dipatch({ type: SUBMIT_RATING, payload }));
};

export const changeUsersFilter = (options = {}) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);
  const data = addPageSize({ ...options, garageId });

  return api.fetchUsers(data)
    .then((payload) => dispatch({ type: CHANGE_USERS_FILTER, data, payload }));
};

export const resetUsersState = () => ({ type: RESET_USERS_STATE });

export const changePassword = (data) => (dispatch, getState) => {
  const state = getState();
  const userId = fromUser.getCurrentUserId(state);
  const garageId = fromUser.getCurrentGarageId(state);

  return api.changePassword(userId, garageId, data)
    .then((payload) => dispatch({ type: CHANGE_PASSWORD, payload }));
};

export const updateUser = (userId, data) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);

  return api.updateUser(userId, garageId, data)
    .then((payload) => dispatch({ type: UPDATE_USER, payload }));
};

export const updateLastLogin = (userId) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);

  return api.updateLastLogin(userId, garageId)
    .then((payload) => dispatch({ type: UPDATE_USER, payload }));
};

export const deactivate2FA = (password) => (dispatch, getState) => {
  const state = getState();
  const userId = fromUser.getCurrentUserId(state);
  const garageId = fromUser.getCurrentGarageId(state);

  return api.deactivate2FA(userId, garageId, password)
    .then((payload) => dispatch({ type: UPDATE_USER, payload }));
};

export const activate2FA = () => (dispatch, getState) => {
  const state = getState();
  const userId = fromUser.getCurrentUserId(state);
  const garageId = fromUser.getCurrentGarageId(state);

  return api.activate2FA(userId, garageId)
    .then((payload) => dispatch({ type: UPDATE_USER, payload }));
};

export const markAllAlarmsAsRead = () => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);
  const userId = fromUser.getCurrentUserId(state);

  return api.markAllAlarmsAsRead(userId, garageId)
    .then((payload) => dispatch({ type: UPDATE_USER, payload }));
};

export const markAlarmAsRead = (uid) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);
  const userId = fromUser.getCurrentUserId(state);

  return api.markAlarmAsRead(userId, garageId, uid)
    .then((payload) => dispatch({ type: UPDATE_USER, payload }));
};

export const removeUsers = (userIds) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);

  return api.removeUsers(userIds, garageId)
    .then(() => dispatch({ type: REMOVE_USERS, userIds }));
};

export const getHashForShopLogin = () => (dispatch, getState) => {
  const currentUserId = fromUser.getCurrentUserId(getState());
  const currentGarageId = fromUser.getCurrentGarageId(getState());
  return api.getHashForShopLogin(currentUserId, currentGarageId);
};
