import React, { Component } from 'react';
import PropTypes from 'prop-types';

class InteractableDiv extends Component {
  static propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
    onDoubleClick: PropTypes.func,
    forwardedRef: PropTypes.func,
    tabIndex: PropTypes.bool,
    dataTest: PropTypes.string,
  };

  static defaultProps = {
    children: null,
    onClick: () => null,
    onDoubleClick: () => null,
    forwardedRef: () => null,
    tabIndex: 0,
    dataTest: '',
  };

  state = {};

  render() {
    const {
      children, onClick, forwardedRef, tabIndex, dataTest, onDoubleClick, ...props
    } = this.props;
    return (
      <div
        role="button"
        onDoubleClick={onDoubleClick}
        onClick={onClick}
        onKeyDown={(ev) => {
          if (ev.key === 'Enter') {
            onClick(ev);
          }
        }}
        ref={forwardedRef}
        tabIndex={tabIndex}
        data-test={dataTest}
        {...props}
      >
        {children}
      </div>
    );
  }
}

export default InteractableDiv;
